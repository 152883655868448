import { http, setHTTPHeader } from './http.service'
import { IUserLogin, IRegisterUser } from '@/types/general'
import { login, logout, signup, register, resetPassword, fetchCurrentUser, fetchInvitedUser, fetchResetUser ,updatePassword, getUserAccounts} from '@/apis/auth.api'

class AuthService {
  async login(user: IUserLogin) {
    return login(user).then((response) => {
      if (response.headers.authorization) {
        const token = response.headers.authorization;
        // CJS - login/logout test
        console.log("Auth:login - setItem")
        sessionStorage.setItem('user', JSON.stringify(response.data))
        setHTTPHeader({ Authorization: token })
        // CJS - Experiment (HTTP Token)
        sessionStorage.setItem('token', token)
      }

      return response.data;
    });
  }

  async logout() {
    return logout().finally(() => {
      delete http.defaults.headers.common['Authorization']
      this.clearCache()
    })
  }

  async signup(user: IRegisterUser) {
    return signup(user);
  }

  async register(user: IRegisterUser) {
    return register(user);
  }

  async resetPassword(email) {
    resetPassword(email);
  }
  async fetchCurrentUser() {
    console.log("AuthService - fetchCurrentUser()")
    return fetchCurrentUser().finally(() => {
      // do something? clearCache?
    })
  }

  async fetchInvitedUser(invitationToken) {
    return fetchInvitedUser(invitationToken).finally(() => {
      // do something? clearCache?
    })
  }

  async fetchResetUser(resetToken) {
    return fetchResetUser(resetToken).finally(() => {
      // do something? clearCache?
    })
  }

  async updatePassword(user: IRegisterUser) {
    return updatePassword(user);
  }

  clearCache(): void {
    console.log("Auth:clearCache")
    sessionStorage.clear()
  }

  getUser() {
    // CJS - login/logout test
    const user = sessionStorage.getItem('user')
    //const user = ""

    if (user) {
      console.log("Auth:getUser")
      try {
        return JSON.parse(user);
      } catch {
        return null;
      }
    }

    return null;
  }

  setUser(user) {
    // CJS - login/logout test
    console.log("Auth:setUser - currently commented out")
    //console.log("Auth:setUser")
    //sessionStorage.setItem('user', JSON.stringify(user))
  }

  getToken() {
    //return localStorage.getItem('token');
    // CJS - Experiment (HTTP Token)
    return sessionStorage.getItem('token');
  }

  async getUserAccounts() {
    return getUserAccounts()
  }
}

const instance = new AuthService();

export default instance;
